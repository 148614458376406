import { Card } from 'antd';
import FilesWrapper from '@/components/files/FilesWrapper';

const FilesScreen = () => {
  return (
    <div className="dashboard-screen files-screen fadeIn">
      <Card>
        <FilesWrapper />
      </Card>
    </div>
  );
};

export default FilesScreen;
